
export default {
  namespaced: true,
  state: {
    applicant: {
      oao: false,
      lastName: '',
      dateOfBirth: '',
      ssn: '',
      typeOfAccount: '',
      creditCardNum: '',
      expirationDate: '',
      cvv: '',
      FHBaccountNumber: '',
      confrimFHBaccountNumber: '',
      mobile: '',
      email: '',
      id: '',
      age: '',
      addressExists: '',  
      mobileFromTSYS: [],
      emailFromTSYS: "",
    },
    cypress: false,
  },
  actions: {
    setOAO ({ commit }, value) {
      commit('setOAO', value)
    },
    setCypress ({ commit }, cypress) {
      commit('setCypress', cypress)
    },
    setApplicantLName ({ commit }, lname) {
      commit('setApplicantLName', lname)
    },
    setApplicantDOB ({ commit }, dob) {
      commit('setApplicantDOB', dob)
    },
    setApplicantSSN ({ commit }, ssn) {
      commit('setApplicantSSN', ssn)
    },
    setTypeOfAcc ({ commit }, accType) {
      commit('setTypeOfAcc', accType)
    },
    setAccNum ({ commit }, accNum) {
      commit('setAccNum', accNum)
    },
    setConfirmAccNum ({ commit }, confirmAccNum) {
      commit('setConfirmAccNum', confirmAccNum)
    },
    setCreditCardNum ({ commit }, creditCardNum) {
      commit('setCreditCardNum', creditCardNum)
    },
    setCardExpirationDate ({ commit }, expirationDate) {
      commit('setCardExpirationDate', expirationDate)
    },
    setCardCVV ({ commit }, cvv) {
      commit('setCardCVV', cvv)
    },
    setPhone ({ commit }, phone) {
      commit('setPhone', phone)
    },
    setEmail ({ commit }, email) {
      commit('setEmail', email)
    },
    setID ({ commit }, id) {
      commit('setID', id)
    },
    setAge ({ commit }, age) {
      commit('setAge', age)
    },
    setAddressExists ({ commit }, addressExists) {
      commit('setAddressExists', addressExists)
    },
    setMobileFromTSYS ({ commit }, mobileFromTSYS) {
      commit('setMobileFromTSYS', mobileFromTSYS)
    },
    setEmailFromTSYS ({ commit }, emailFromTSYS) {
      commit('setEmailFromTSYS', emailFromTSYS)
    }

  },
  mutations: {
    setApplicantLName (state, value) {
      state.applicant.lastName = value
    },
    setApplicantDOB (state, value) {
      state.applicant.dateOfBirth = value
    },
    setApplicantSSN (state, value) {
      state.applicant.ssn = value
    },
    setTypeOfAcc (state, value) {
      state.applicant.typeOfAccount = value
    },
    setAccNum (state, value) {
      state.applicant.FHBaccountNumber = value
    },
    setConfirmAccNum (state, value) {
      state.applicant.confrimFHBaccountNumber = value
    },
    setCreditCardNum (state, value) {
      state.applicant.creditCardNum = value
    },
    setCardExpirationDate (state, value) {
      state.applicant.expirationDate = value
    },
    setCardCVV (state, value) {
      state.applicant.cvv = value
    },
    setPhone (state, value) {
      state.applicant.mobile = value
    },
    setEmail (state, value) {
      state.applicant.email = value
    },
    setID (state, value) {
      state.applicant.id = value
    },
    setAge (state, value) {
      state.applicant.age = value
    },
    setAddressExists (state, value) {
      state.applicant.addressExists = value
    },
    setMobileFromTSYS (state, value) {
      state.applicant.mobileFromTSYS = value
    },
    setEmailFromTSYS (state, value) {
      state.applicant.emailFromTSYS = value
    },
    setCypress (state, value) {
      state.cypress = value
    },
    setOAO (state, value) {
      state.oao = value
    }
  },
  getters: {
    applicantLastName: (state) => state.applicant.lastName,
    applicantDob: (state) => state.applicant.dateOfBirth,
    applicantSsn: (state) => state.applicant.ssn,
    applicantTypeOfAcc: (state) => state.applicant.typeOfAccount,
    applicantAccNum: (state) => state.applicant.FHBaccountNumber,
    applicantConfirmAccNum: (state) => state.applicant.confrimFHBaccountNumber,
    applicantCreditCardNum: (state) => state.applicant.creditCardNum,
    applicantCardExpirationDate: (state) => state.applicant.expirationDate,
    applicantPhone: (state) => state.applicant.mobile,
    applicantEmail: (state) => state.applicant.email,
    applicantID: (state) => state.applicant.id,
    applicantAge: (state) => state.applicant.age,
    applicantAddressExists: (state) => state.applicant.addressExists,
    getApplicantMobileFromTSYS: (state) => state.applicant.mobileFromTSYS,
    getApplicantEmailFromTSYS: (state) => state.applicant.emailFromTSYS,
    getApplicantCardCVV: (state) => state.applicant.cvv,
    isCypressTest: (state) => state.cypress,
    getOAO: (state) => state.oao
  }
}
