<template>
  <div class="wallpaper">
    <div class="content" id="something-went-wrong">
      <div class="headerLog">
        <a href="https://www.fhb.com/"><img class="header-logo" srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x" src="@/assets/images/FHBColorLogo-2x.png" alt="First Hawaiian Bank home logo" title="FHB home" /></a>
      </div>
      <div class="support-logo">
        <img class="support-logo" srcset="@/assets/images/support.png 1x @/assets/images/support-2x.png 2x" src="@/assets/images/support-2x.png" alt="person with headset in front of a browser illustration" />
      </div>
      <div class="content-title">
        <h1>Something went wrong</h1>
      </div>
      <div class="content-text">
        <p class="content-text-p">
          You have exceeded the number of attempts allowed.
        </p>
        <p class="content-text-p">
          For assistance, please visit your nearest <a href="https://locations.fhb.com/" target="_blank">branch location</a> or call us at (808) 643-4343 or toll-free at (888) 643-4343, Monday - Friday 8am - 7pm HST.
        </p>
      </div>

      <div class="button-container">
        <a :href="fhbOnline"><button class="submitBtn" type="submit" id="exit-something-went-wrong-btn" data-test="something-wrong-exit-btn">EXIT</button></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SendCode',
  data() {
    return {
      fhbOnline: process.env.VUE_APP_FHB_ONLINE_URL
    }
  },
  methods: {
    handleOnComplete(value) {
      console.log('OTP completed: ', value)
    },
    handleOnChange(value) {
      console.log('OTP changed: ', value)
    }
  }
}
</script>

<style scoped>
.wallpaper {
  background-image: url("~@/assets/images/orange@2x.svg");
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef5ee;
}

.content {
  width: max(529px, 40%);
  padding: 20px 30px 40px 30px;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.8);
}

.resend-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 450;
}

.resend-code-btn {
  border: none;
  background-color: #FFFFFF;
  font-size: 18px;
  font-weight: 450;
  text-decoration: underline;

}

.button-container {
  text-align: center;
}

.submitBtn {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: inline-block;
  /* font-family: "CircularXX TT"; */
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.headerLog {
  text-align: center;
  margin-top: 36px;
}

.support-logo {
  text-align: center;
  margin-top: 50px;
}

.support-logo img {
  height: auto;
  width: 100px;
}

.content-title {
  text-align: center;
  font-size: 28px;
}

.content-text {
  text-align: center;
  font-size: 18px;
  font-weight: 450;
  margin-bottom: 5px;
}

.content-text-p {
  margin-top: 5px;
  margin-bottom: 0px;
}

.send-code {
  margin-top: 40px;
  text-align: center;
  font-size: 18px;
  justify-content: center;
}
</style>
<!-- <style lang="less">
.otp-input {
  width: 54px;
  height: 64px;
  padding: 5px;
  margin: 0 10px;
  font-size: 30px;
  box-sizing: border-box;
  border-radius: 3px;
  color: #333333;
  border: 1.5px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

.optInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style> -->
